import TICKET_1 from 'assets/images/ticket_1.png';
import TICKET_2 from 'assets/images/ticket_2.png';
import TICKET_3 from 'assets/images/ticket_3.png';
import TICKET_4 from 'assets/images/ticket_4.png';
import React, { Dispatch, SetStateAction } from 'react';
import Header from 'shared/components/header';

import { useStyles } from './styles';

export const SeasonTickets: React.FC<{
  setMobileMenu: Dispatch<SetStateAction<boolean>>;
}> = ({ setMobileMenu }) => {
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <Header setMobileMenu={setMobileMenu} isBlack />
      <div className={classes.container}>
        <span className={classes.title}>Абонементи</span>
        <div className={classes.flex}>
          <img src={TICKET_1} className={classes.photo_one} alt="photo" />
          <div className={classes.list}>
            <div className={classes.subtitle}>Ручна ліпосакція</div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Абонемент “Базовий”</div>
                <div className={classes.price}>5600 грн</div>
              </div>
              <p className={classes.paragraph}>
                Включає в себе 10 процедур ручної ліпосакції + 1 в подарунок.
                Одна процедура складається з медового, ручного, лімфодренажного,
                вакуумного масажу та завершального обгортання з термокремом.
              </p>
              <p className={classes.paragraph}>Вартість разової процедури без абонемента - 800 грн</p>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Абонемент “S”</div>
                <div className={classes.price}>7650 грн</div>
              </div>
              <p className={classes.paragraph}>
                Найулюбленіший абонемент наших дівчат. В нього входить 10 процедур ручної ліпосакції та 5 обгортань,
                що зменшують об’єми тіла та виводять зайву рідину (STYX або Bruno Vassari) + 1 процедура в подарунок.
              </p>
              <p className={classes.paragraph}>Економія при покупці - 6350 грн</p>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Абонемент “XS”</div>
                <div className={classes.price}>9100 грн</div>
              </div>
              <p className={classes.paragraph}>
                Включає в себе 10 процедур ручної ліпосакції + 5 ін’єкцій ліполітиків + 1 процедура в подарунок.
                Підходить для тих зон, які не підлягають корекції за допомогою харчування, спорту та масажу.
              </p>
              <p className={classes.paragraph}>Економія при покупці - 5800 грн</p>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Абонемент “XS MAX”</div>
                <div className={classes.price}>10950 грн</div>
              </div>
              <p className={classes.paragraph}>
                Включає в себе цілих 20 процедур, серед яких: 10 процедур ручної ліпосакції,
                5 обгортань, що зменшують об’єми тіла та виводять рідину (STYX або Bruno Vassari) та
                5 ін’єкцій ліполітика для корекції проблемних зон + 1 процедура в подарунок.
              </p>
              <p className={classes.paragraph}>
                Економія при покупці - 8050 грн
              </p>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>“S” холодні обгортання</div>
                <div className={classes.price}>7650 грн</div>
              </div>
              <p className={classes.paragraph}>
                Абонемент ідеально підійде для матусь, які на ГВ, а також для тих, хто має схильність
                до захворювань серцево-судинної системи.
                Включає в себе 10 процедур ручної ліпосакції + 5 холодних обгортань + 1 процедура в подарунок.
              </p>
              <p className={classes.paragraph}>
                Економія при покупці – 6350 грн
              </p>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>разова процедура обгортання</div>
                <div className={classes.price}>1000 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>
                  курс із 5 процедур обгортання{' '}
                </div>
                <div className={classes.price}>3500 грн</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.flex}>
          <div className={classes.list}>
            <div className={classes.subtitle}>Ліфтинг обличчя</div>
            <div className={classes.mainTitle}>
              Ми розробили абонементи на 5,10 або 20 процедур авторського масажу обличчя.
              Абонемент з п’яти процедур - це найпопулярніший подарунковий сертифікат в нашому центрі,
              а з двадцяти - це вже освідчення собі у коханні.
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Абонемент “PUSH UP mini”</div>
                <div className={classes.price}>3750 грн</div>
              </div>
              <p className={classes.paragraph}>
                Ліфтинг обличчя включає в себе 5 процудур + 1 процедура в подарунок
              </p>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Абонемент “PUSH UP midi”</div>
                <div className={classes.price}>6000 грн</div>
              </div>
              <p className={classes.paragraph}>
                Ліфтинг обличчя включає в себе 10 процедур + 1 процедура в подарунок
              </p>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Абонемент “PUSH UP maxi” </div>
                <div className={classes.price}>9500 грн</div>
              </div>
              <p className={classes.paragraph}>
                Ліфтинг обличчя включає в себе 20 процедур + 2 процедури в подарунок
              </p>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>
                  ліфтинг обличчя без абонементу
                </div>
                <div className={classes.price}>800 грн</div>
              </div>
              <div className={classes.timeline}>Тривалість 50 хв</div>
            </div>
          </div>
          <img src={TICKET_2} className={classes.photo_two} alt="photo" />
        </div>
        <span className={classes.title}>Інші види послуг</span>
        <div className={classes.flex}>
          <img src={TICKET_3} className={classes.photo_one} alt="photo" />
          <div className={classes.list}>
            <div className={classes.subtitle}>Тейпування</div>
            <div className={classes.mainTitle}>
              Необхідне доповнення до будь-якого абонемента, якщо ваша пріоритетна зона - живіт і боки.
              Ми робимо тейпування для корекції фігури, відновлення після пологів а також лімфодренажне.
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Передня частина ніг</div>
                <div className={classes.price}>450 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>внутрішня поверхня стегна</div>
                <div className={classes.price}>450 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Задня поверхня стегна</div>
                <div className={classes.price}>500 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>коліна</div>
                <div className={classes.price}>350 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>
                  КОМПЛЕКС: передня частина ніг + внутрішня + коліна
                </div>
                <div className={classes.price}>850 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Холка</div>
                <div className={classes.price}>450 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Галіфе</div>
                <div className={classes.price}>450 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Зона сідниць</div>
                <div className={classes.price}>500 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>коМПЛЕКС: Сідниці + галіфе</div>
                <div className={classes.price}>750 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>живіт</div>
                <div className={classes.price}>550 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Боки</div>
                <div className={classes.price}>400 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Комплекс: живіт + боки</div>
                <div className={classes.price}>800 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Ікри</div>
                <div className={classes.price}>350 грн</div>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>зона рук</div>
                <div className={classes.price}>450 грн</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.flex}>
          <div className={classes.list}>
            <div className={classes.subtitle}>Комплексні послуги</div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>FULL BODY BLADE (FBB)</div>
                <div className={classes.price}>5800 грн</div>
              </div>
              <p className={classes.paragraph}>Комплекс, що містить 10 процедур загального лімфодренажного
                масажу з використанням блейдів. Ідеально підійде для того,
                щоб прискорити обмін речовин у всьому організмі та придати відчуття легкості й тонусу.</p>
              <p className={classes.paragraph} style={{marginTop: '5px', fontWeight: 600}}>
                Процедура FBB без абонемента - 1000 грн</p>
              <div className={classes.timeline}>Тривалість 60 хв</div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>DEEP BODY BLADE (DBB)</div>
                <div className={classes.price}>5800 грн</div>
              </div>
              <p className={classes.paragraph}>Найефективніший комплекс процедур для боротьби з
                фіброзним целюлітом (ямки та бугорки).
                Включає в себе 10 процедур,
                кожна з яких поєднує ручну ліпосакцію та блейди.
                Працюємо зонами. Процедура має підвищену ефективність.</p>
              <p className={classes.paragraph} style={{marginTop: '5px', fontWeight: 600}}>
                Процедура DBB  без абонемента - 1000 грн</p>
              <div className={classes.timeline}>Тривалість 60 хв</div>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Light Fit </div>
                <div className={classes.price}>1000 грн</div>
              </div>
              <p className={classes.paragraph}>
                Найефективніша процедура для худого обличчя, така ж непощадна до второго підборіддя та брилів,
                як наші ЗСУ до самі знаєте кого.
                Проводиться з використанням непрямого ліполітика. Абонемента немає, бо ефект видно одразу
              </p>
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                <div className={classes.name}>Лімфодренажний масаж</div>
                <div className={classes.price}>1000 грн</div>
              </div>
            </div>
          </div>
          <img src={TICKET_4} className={classes.photo_four} alt="photo" />
        </div>
      </div>
    </div>
  );
};

export default SeasonTickets;
