import { createUseStyles } from 'react-jss';
import { IAppTheme } from 'shared/styles/theme';

import { MAX_WIDTH_1000, MAX_WIDTH_1200, MAX_WIDTH_767 } from '../../constans';

export const useStyles = createUseStyles((theme: IAppTheme) => ({
  wrapper: {
    display: 'flex',
    padding: '140px 90px 60px',
    justifyContent: 'space-between',
    alignItems: 'start',
    [MAX_WIDTH_1200]: {
      padding: '100px 60px 60px',
    },
    [MAX_WIDTH_1000]: {
      padding: '100px 30px 30px',
    },
    [MAX_WIDTH_767]: {
      padding: '50px 20px 36px',
    },
  },
  logo: {
    width: '104px',
    height: '39px',
    cursor: 'pointer',
    [MAX_WIDTH_767]: {
      display: 'none',
    },
  },
  navigation: {
    display: 'flex',
    [MAX_WIDTH_1200]: {
      flexWrap: 'wrap',
    },
    [MAX_WIDTH_767]: {
      // width: '100%',
      // flexDirection: 'column',
      // alignItems: 'center',
      // justifyContent: 'center',
      textAlign: 'center'
    },
  },
  name: {
    display: 'block',
    textDecoration: 'none',
    fontFamily: theme.fonts.GraphikLCG.Semibold,
    fontSize: '16px',
    color: theme.colors.black,
    [MAX_WIDTH_767]: {
      textAlign: 'left'
    },
  },
  marginTop: {
    marginTop: '15px'
  },
  socialItem: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginTop: '8px',
    color: theme.colors.black,
    [MAX_WIDTH_767]: {
      justifyContent: 'start'
    },
  },
  socialText: {
    fontFamily: theme.fonts.GraphikLCG.Regular,
    fontSize: '14px',
    color: theme.colors.black
  },
  socialIcon: {
    width: '20px',
    height: '20px',
    marginRight: '9px',
    display: 'block'
  },
  subType: {
    fontFamily: theme.fonts.GraphikLCG.Regular,
    fontSize: '16px',
    color: theme.colors.black,
    marginTop: '7px',
    '&:nth-of-type(1)': {
      marginTop: '10px',
    },
  },
  contactItem: {
    fontFamily: theme.fonts.GraphikLCG.Regular,
    fontSize: '14px',
    color: theme.colors.black,
    marginTop: '8px',
    '& span':  {
      fontFamily: theme.fonts.GraphikLCG.Semibold,
    },
    '& a': {
      marginTop: '5px',
      display: 'block',
      textDecoration: 'none',
      color: theme.colors.black
    },
      [MAX_WIDTH_767]: {
        textAlign: 'left'
      },
  },
  column: {
    '&:first-child': {
      marginRight: '80px',
      [MAX_WIDTH_1200]: {
        marginRight: '20%',
        width: '50%',
      },
      [MAX_WIDTH_767]: {
        width: '33%',
        // marginRight: 0
      },
    },
    '&:nth-of-type(2)': {
      marginRight: '40px',
      [MAX_WIDTH_1200]: {
        marginRight: '0%',

      },
      [MAX_WIDTH_767]: {
        // width: '100%',
        // marginRight: 0,
        // marginTop: '15px'
        // order: 3
        width: '47%',
      },
    },
    '&:nth-of-type(3)': {
      marginRight: '112px',
      [MAX_WIDTH_1200]: {
        marginRight: '20%',
        width: '50%',
      },
      [MAX_WIDTH_767]: {
        // width: '100%',
        // marginRight: 0,
        // marginTop: '15px'
        // order: 2
        marginRight: '3%',
      },
    },
    '&:nth-of-type(4)': {
      marginRight: '114px',
      [MAX_WIDTH_1200]: {
        marginRight: '0px',
        width: '30%',
      },
      [MAX_WIDTH_767]: {
        // width: '100%',
        // marginRight: 0
        width: '42%',
        position: 'relative',
        top: '-25px'
      },
    },
    '&:nth-of-type(5)': {
      [MAX_WIDTH_1200]: {
        marginTop: '24px',
      },
      [MAX_WIDTH_767]: {
        // width: '100%',
        // marginRight: 0
      },
    },
  },
}));
