import CLOSE_ICON from 'assets/images/close-icon.svg';
import { Dispatch, SetStateAction } from 'react';

import { TICKETS_SENTENCES } from '../../../constans';
import { useStyles } from './styles';

const WorkModal: React.FC<{
  isOpen: boolean;
  setWorkModal: Dispatch<SetStateAction<boolean>>;
  ticket: string
}> = ({ isOpen, setWorkModal, ticket }) => {
  const classes = useStyles({ isOpen: isOpen });
  const currentSentence = TICKETS_SENTENCES.find((item) => item.type === ticket);
  return (
    <div
      className={`${classes.container}${!isOpen ? classes.none : ''}`}
      onClick={() => {
        setWorkModal(false);
      }}
    >
      <div className={classes.wrapper} onClick={(e) => e.stopPropagation()}>
        <img
          src={CLOSE_ICON}
          className={classes.closeIcon}
          onClick={() => {
            setWorkModal(false);
          }}
          alt="close icon"
        />
        <div className={classes.textBlock}>
          <div className={classes.name}>{currentSentence?.name}</div>
          <div className={classes.text}>{currentSentence?.sentence}</div>
        </div>
      </div>
    </div>
  );
};

export default WorkModal;
