import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller, Element } from 'react-scroll';
import { useWindowDimensions } from 'services/hooks/useDimensions/useWindowDimension';
import { EDimensions } from 'shared/constans';

import QuestionRender from './question-render';
import { useStyles } from './styles';


const services = [
  {
    key: 1,
    title: 'Ручна ліпосакція',
    description:
      'Альтернатива хірургічному втручанню. Особливість техніки в тому, ' +
      'що завдяки поєднанню найефективніших методик масажу в певній послідовності, ' +
      'запускається процес спалювання жиру на проблемних зонах та зменшення об’ємів тіла до -15 см за курс.',
  },
  {
    key: 2,
    title: 'Обгортання',
    description:
      'Необхідні для того, щоб: зменшити об’єми тіла в найкоротші сроки, вивести зайву рідину, ' +
      'позбавитись набряклості, підвищити пружність шкіри, зменшити прояви целюліту.'
  },
  {
    key: 3,
    title: 'Ліполітики',
    description:
      'Зменшують локальні жирові відкладення, які не вдається прибрати за ' +
      'допомогою харчування чи ручної ліпосакції, і часто мають невеликий об\'єм.\n',
  },
  {
    key: 4,
    title: 'Ліфтинг обличчя',
    description:
      'Це повністю авторська програма, в якій об`єднані найефективніші ' +
      'методики масажу обличчя таким чином, щоб вже з першої процедури ви побачили ВАУ. Проводиться в декілька етапів.',
  },
  {
    key: 5,
    title: 'Мікротоки',
    description:
      'Контури обличчя стають чіткішими, з’являються заповітні «кути молодості». ' +
      'Зникають заломи, а також ця процедура запобігає їх появі. ' +
      'Звужуються пори і нормалізується вироблення шкірного сала. Шкіра обличчя та тон вирівнюються.',
  },
  {
    key: 6,
    title: 'Тейпування',
    description: 'Методика спеціального фіксування тейпів, при якій піднімається шкіра й підшкірна клітковина, ' +
      'де й розташований целюліт. Відбувається виведення зайвої рідини й токсинів,' +
      ' ніби вам постійно роблять лімфодренажний масаж.\n',
  },
  {
    key: 7,
    title: 'Body BLADE',
    description:
      'Унікальна технологія, за якої ефективність масажу збільшується в кілька разів. ' +
      'Завдяки блейдам, які вироблені з медичної сталі, можна опрацювати ті зони, до яких складно добратися руками. ' +
      'Він не травмує судини та прибирає спайки фасцій, які утворюють той самий целюліт у вигляді бугорців та ямок.',
  },
  {
    key: 8,
    title: 'Лімфодренажний масаж',
    description:
      'Необхідний вам, якщо ви схильні до набряків, відчуваєте втому вже зранку, ' +
      'а ввечері часто помічаєте сліди від шкарпеток та одягу на тілі. ' +
      'Процедура робиться на все тіло, займає 60 хвилин, покращує стан шкіри, ' +
      'самопочуття, якість сну, підвищує імунітет та виводить зайву рідину.',
  },
  {
    key: 9,
    title: 'Масаж спини',
    description:
      'Окремий вид задоволення, який допоможе зняти напругу з м’язів та розправити плечі,' +
      ' рекомендуємо відвідувати регулярно, задля ідеальної постави ' +
      'та впевненої ходи. Адже здорова спина — це дуже красиво.',
  },
  {
    key: 10,
    title: 'Загальний масаж',
    description:
      'Передбачає активне залучення всіх частин тіла. На кожну зону відводиться близько 10 хвилин, ' +
      'ви відчуваєте кожен м’яз свого тіла, кровоносна та ' +
      'лімфатична система активуються, а кожна клітина організму наповниться киснем. ' +
      'Цією процедурою ідеально завершити складний тиждень, або, навпаки, розпочати новий.',
  },
];
const Services: React.FC<{
  isTestModalOpen: boolean;
  setTestModalOpen: Dispatch<SetStateAction<boolean>>;
  setTicket: Dispatch<SetStateAction<string>>;
  setWorkModal: Dispatch<SetStateAction<boolean>>
  ticket: string
}> = ({ setTestModalOpen, setTicket, ticket, setWorkModal }) => {
  const classes = useStyles();
  const dimensions = useWindowDimensions();
  const {hash, key} = useLocation()
  useEffect(() => {
    if (hash && hash === '#service') {
      scroller.scrollTo('services', {
        duration: 1000,
        delay: 100,
        smooth: true,
      })
      window.history.replaceState(null, 'New Page Title', '/')
    }
  }, [hash, key])
  return (
    <Element name="services">
      <span className={classes.title} id="service">Види послуг</span>
      <div className={classes.servicesWrapper}>
        {services.map(({ key, title, description }) => (
          <div className={classes.serviceItem} key={key}>
            <div className={classes.serviceWrapper}>
              <span className={classes.serviceNumber}>{key}</span>
              <span className={classes.serviceTitle}>{title}</span>
              <p className={classes.serviceDescription}>{description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.quest_wrapper}>
        <p className={classes.questTitle}>
          Ми створили тест, який допоможе підібрати необхідний комплекс процедур
          саме для тебе. Пройди його та отримай <span>знижку </span> на будь-який абонемент.
        </p>
        {dimensions === EDimensions.SMALL && (
          <div
            className={classes.button}
            onClick={() => setTestModalOpen(true)}
          >
            Пройти тест
          </div>
        )}
        {dimensions !== EDimensions.SMALL &&
          <QuestionRender setTicket={setTicket} ticket={ticket} setWorkModal={setWorkModal}/>}
      </div>
    </Element>
  );
};

export default Services;
