import React, { Dispatch, SetStateAction } from 'react';
import { IValidateModel, TEST_RESULT, TICKETS } from 'shared/interfaces';

import { useStyles } from './styles';
import { displayResultSeasons } from './util/util';

interface ITestResultModel {
  validateObject: IValidateModel;
  setWorkModal: Dispatch<SetStateAction<boolean>>;
  setTicket: Dispatch<SetStateAction<string>>;
  ticket: string
}

const tickets = [
  {
    key: 1,
    name: '«XS max»',
    types: [TEST_RESULT.TYPE_1, TEST_RESULT.TYPE_2, TEST_RESULT.TYPE_3],
    ticket_type: TICKETS.XS_MAX
  },
  {
    key: 2,
    name: '«Light fit»',
    types: [TEST_RESULT.TYPE_1, TEST_RESULT.TYPE_3],
    ticket_type: TICKETS.LIGHT_FIT
  },
  {
    key: 3,
    name: '«S»',
    types: [TEST_RESULT.TYPE_1, TEST_RESULT.TYPE_2, TEST_RESULT.TYPE_3],
    ticket_type: TICKETS.S
  },
  {
    key: 4,
    name: '«FBB»',
    types: [TEST_RESULT.TYPE_1, TEST_RESULT.TYPE_2, TEST_RESULT.TYPE_3, TEST_RESULT.TYPE_5],
    ticket_type: TICKETS.FBB
  },
  {
    key: 5,
    name: '«DBB»',
    types: [TEST_RESULT.TYPE_1, TEST_RESULT.TYPE_2, TEST_RESULT.TYPE_3],
    ticket_type: TICKETS.DBB
  },
  {
    key: 6,
    name: 'Тейпи',
    types: [TEST_RESULT.TYPE_1, TEST_RESULT.TYPE_2],
    ticket_type: TICKETS.TAPES
  },
  {
    key: 7,
    name: '«XS»',
    types: [TEST_RESULT.TYPE_1, TEST_RESULT.TYPE_2, TEST_RESULT.TYPE_3, TEST_RESULT.TYPE_5],
    ticket_type: TICKETS.XS
  },
  {
    key: 8,
    name: '«S холодні обгортання»',
    types: [TEST_RESULT.TYPE_1, TEST_RESULT.TYPE_2, TEST_RESULT.TYPE_3, TEST_RESULT.TYPE_5],
    ticket_type: TICKETS.COLD_WRAPS
  },
  {
    key: 9,
    name: 'Ліфтинг обличчя',
    types: [TEST_RESULT.TYPE_1, TEST_RESULT.TYPE_3],
    ticket_type: TICKETS.FACE_LIFTING
  },
  {
    key: 10,
    name: '«Базовий»',
    types: [TEST_RESULT.TYPE_2, TEST_RESULT.TYPE_5],
    ticket_type: TICKETS.BASE
  },
  {
    key: 11,
    name: '«Базовий + тейпи»',
    types: [TEST_RESULT.TYPE_3],
    ticket_type: TICKETS.BASE_PLUS_TYPE
  },
  {
    key: 12,
    name: 'Тейпи + «S»',
    types: [TEST_RESULT.TYPE_5],
    ticket_type: TICKETS.S_PLUS_TYPE
  },
];

const TestResult: React.FC<ITestResultModel> = (
  {
    validateObject,
    setTicket,
    setWorkModal,
  }
  ) => {
  const currentSeason = displayResultSeasons(validateObject);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.title}>
        Абонементи та послуги, які вам підходять:
      </span>
      {TEST_RESULT.TYPE_4 !== currentSeason ? (
        <>
          <div className={classes.wrapper}>
            {tickets
              .filter((season) => season.types.includes(currentSeason))
              .map(({ name, ticket_type, key }) => (
                <div key={key} className={classes.seasonTicket} onClick={() => {
                  setTicket(ticket_type);
                  setWorkModal(true);
                }}>
                  {name}
                </div>
              ))}
            <p className={classes.welcome}>Вітаю! Ви отримали знижку 10% на абонемент.</p>
          </div>
          {(
            <a href="season-tickets" className={classes.price}>
              Дізнатись вартість
            </a>
          )}
        </>
      ) : (
        <div className={classes.box}>
          <span className={classes.phoneTitle}>
            Для підбору процедури зверніться за телефоном
          </span>
          <div className={classes.phoneWrapper}>
            <p className={classes.phone}>+380983131300</p>
            <p className={classes.phone}>+380509500100</p>
            <p className={classes.phone}>+380977011770</p>
            <p className={classes.phone}>+380977490707</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestResult;
