import INST from 'assets/images/inst.svg';
import PHOTO from 'assets/images/salon-image.png';
import TELEGA from 'assets/images/telega.svg';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller, Element } from 'react-scroll';

import { useStyles } from './styles';


const Franchise: React.FC<{
  setWorkModal: Dispatch<SetStateAction<boolean>>;
}> = ({ setWorkModal }) => {
  const classes = useStyles();
  const {hash, key} = useLocation()
  useEffect(() => {
    if (hash && hash === '#franchise') {
      scroller.scrollTo('franchises', {
        duration: 1000,
        delay: 100,
        smooth: true,
      })
      window.history.replaceState(null, 'New Page Title', '/')
    }
  }, [hash, key])
  return (
    <Element name="franchises">
      <div className={classes.wrapper} id="franchise">
        <img src={PHOTO} alt="photo" className={classes.photo} />
        <div className={classes.mobileFranchise}>
        </div>
        <div className={classes.textSide}>
          <span className={classes.title}>Франшиза</span>
          <div className={classes.description}>
            Доброго дня, красуне!
            Мене звати Віолета, я засновниця мережі <span>BODY CONTROL</span>.
            П‘ять років тому я винайшла методику масажу,
            яка гарантовано прибирає зайві сантиметри та целюліт,
            а роком пізніше відкрила найбільший центр ручної корекції фігури на <span>210 м2 </span>
            у самому серці Харкова.
            З того часу мережа доповнилася ще двома центрами в Києві та центром в Одесі.
            Про нас говорять, на нас рівняються, ми задаємо тренди й використовуємо найдієвіші способи ще до того,
            як це стане мейнстримом.
            Ми вбережемо вас від зайвих витрат і будь-яких складнощів, бо пройшли через них самі й готові гарантувати,
            що це найкрасивіший бізнес України.
            Ми навчимо як заробляти від <span>5000$</span> на місяць без смс та реєстрації.
            Всього місяць на відкриття та навчання співробітників.
            Самоокупність <span>6</span> місяців і вам не знадобиться навіть грабувати Швейцарський банк заради цього.
            Поговоримо?
          </div>
          <div
            className={classes.franchiseInfo}>
            <a
              target="_blank"
              href='https://instagram.com/violet_kaaa?igshid=YmMyMTA2M2Y='
              className={classes.franchiseItem}
            >
              <img src={INST} alt="logo" />
              <div>@violet_kaaa</div>
            </a>
            <a
              target="_blank"
              href='https://t.me/Violetta_body_control_07'
              className={classes.franchiseItem}
            >
              <img src={TELEGA} alt="logo" />
              <div>@Violetta_body_control_07</div>
            </a>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Franchise;
