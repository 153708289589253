import STARS_IMAGE from 'assets/images/stars.svg';
import { createUseStyles } from 'react-jss';
import { MAX_WIDTH_1000, MAX_WIDTH_1200, MAX_WIDTH_767 } from 'shared/constans';
import { IAppTheme } from 'shared/styles/theme';

export const useStyles = createUseStyles((theme: IAppTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1026px',
    margin: '120px auto 0',
    [MAX_WIDTH_1200]: {
      padding: '0 30px',
    },
    [MAX_WIDTH_1000]: {
      flexDirection: 'column',
      margin: '100px auto 0',
      padding: '0 40px',
    },
    [MAX_WIDTH_767]: {
      maxWidth: 'unset',
      margin: '50px auto 0',
    },
  },
  photo: {
    marginRight: '180px',
    width: '482px',
    [MAX_WIDTH_1000]: {
      width: '100%',
      margin: '35px auto 0',
    },
  },
  textSide: {
    position: 'relative',
    '&:after': {
      content: 'close-quote',
      position: 'absolute',
      bottom: '-20px',
      width: '120px',
      height: '110px',
      right: '-10%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundImage: `url(${STARS_IMAGE})`,
      textAlign: 'center',
      [MAX_WIDTH_1000]: {
        display: 'none',
      },
    },
    [MAX_WIDTH_1000]: {
      order: '-1',
      padding: '0 40px',
    },
    [MAX_WIDTH_767]: {
      padding: '0px',
    },
  },
  title: {
    fontFamily: theme.fonts.PlayfairDisplay.Bold,
    color: theme.colors.black,
    fontSize: '48px',
    display: 'block',
    [MAX_WIDTH_1000]: {
      fontSize: '24px',
      textAlign: 'center',
    },
    [MAX_WIDTH_767]: {
      fontSize: '18px',
    },
  },
  description: {
    fontFamily: theme.fonts.GraphikLCG.Regular,
    color: theme.colors.black,
    marginTop: '57px',
    fontSize: '16px',
    lineHeight: '24px',
    '& span': {
      fontFamily: theme.fonts.GraphikLCG.Medium,
    },
    [MAX_WIDTH_1000]: {
      textAlign: 'center',
    },
    [MAX_WIDTH_767]: {
      fontSize: '16px',
      marginTop: '18px',
    },
  },
  desktopFranchise: {
    [MAX_WIDTH_1000]: {
      // display: 'none',
    },
  },
  mobileFranchise: {
    display: 'none',
    [MAX_WIDTH_1000]: {
      // display: 'block',
    },
  },
  franchiseInfo: {
    fontFamily: theme.fonts.GraphikLCG.Regular,
    color: theme.colors.black,
    fontSize: '20px',
    marginTop: '15px',
    [MAX_WIDTH_1000]: {
      marginTop: '34px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    [MAX_WIDTH_767]: {
      flexDirection: 'column',
      alignItems: 'start',
    }
  },
  franchiseItem: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.black,
    '& img':{
      marginRight: '15px',
      width: '24px',
      height: '24px',
    },
    '&:last-child': {
      marginTop: '10px',
      [MAX_WIDTH_1000]: {
        marginTop: '0px',
        marginLeft: '24px'
      },
      [MAX_WIDTH_767]: {
        marginLeft: '0',
        marginTop: '15px',
      }
    }
  }
}));
