export interface IDataModel {
  key: number;
  title: string;
  question: string;
  answers: {
    key: number;
    name: string;
    second_row: boolean;
    checkbox: boolean;
    three_row?: boolean;
  }[];
}

export interface IAnswerModel {
  [key: string]: boolean;
}

export interface IValidateModel {
  [key: string]: boolean | number | undefined;
}

export const enum TEST_RESULT {
  TYPE_1 = 'type_1',
  TYPE_2 = 'type_2',
  TYPE_3 = 'type_3',
  TYPE_4 = 'type_4',
  TYPE_5 = 'type_5'
}

export const enum TICKETS {
  XS_MAX = 'S_ MAX',
  LIGHT_FIT = 'LIGHT_FIT',
  S = 'S',
  FBB = 'FBB',
  DBB = 'DBB',
  TAPES = 'TAPES',
  XS = 'XS',
  COLD_WRAPS = 'COLD_WRAPS',
  FACE_LIFTING = 'FACE_LIFTING',
  BASE = 'BASE',
  BASE_PLUS_TYPE = 'BASE_PLUS_TYPE',
  S_PLUS_TYPE = 'S_PLUS_TYPE',
}
