import IMAGE_1 from 'assets/images/image_71.png';
import IMAGE_2 from 'assets/images/image_72.png';
import IMAGE_3 from 'assets/images/image_73.png';
import IMAGE_4 from 'assets/images/image_74.png';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller, Element } from 'react-scroll';
import { useWindowDimensions } from 'services/hooks/useDimensions/useWindowDimension';
import Slider from 'shared/components/Slider';
import { EDimensions } from 'shared/constans';

import { useStyles } from './styles';

const testimonials = [
  {
    key: 1,
    name: 'Тетяна',
    testimonial:
      'Щоразу, коли я відвідую цей центр, перше, що впадає мені в око, - ' +
      'це приємні дівчата на ресепшні, які завжди усміхнені та ввічливі. ' +
      'Це налаштовує на позитивний лад перед початком будь-якої процедури. ' +
      'Мені не страшно віддавати себе в руки фахівців Body control,' +
      ' оскільки рівень їхньої кваліфікації не викликає в мене сумнівів.',
    email: '@tanyaparfileva',
    logo: IMAGE_1,
    link: 'https://www.instagram.com/tanyaparfileva/'
  },
  {
    key: 2,
    name: 'Лариса',
    testimonial:
      'Велике спасибі майстрам за складну, ' +
      'але на совість виконану роботу! Особливо за індивідуальний підхід і швидкість. ' +
      'Обов`язково прийду до Вас ще не раз!',
    email: '@lyalya_bounty',
    logo: IMAGE_2,
    link: 'https://www.instagram.com/lyalya_bounty/'
  },
  {
    key: 3,
    name: 'Слава',
    testimonial:
      'Була в Body control на ручній ліпосакції. У дівчаток просто золоті руки! Зробили мені прекрасну фігуру.',
    email: '@babaslavka',
    logo: IMAGE_3,
    link: 'https://www.instagram.com/babaslavka/'
  },
  {
    key: 4,
    name: 'Галина',
    testimonial:
      'Відвідувала ручний масаж, все пройшло чітко за часом, дякую що привели до ладу моє тіло.',
    email: '@galyna_naumenko',
    logo: IMAGE_4,
    link: 'https://www.instagram.com/galyna_naumenko/'
  },
];
const Testimonials: React.FC = () => {
  const classes = useStyles();
  const dimensions = useWindowDimensions();
  const isMobile = EDimensions.SMALL === dimensions;
  const {hash, key, pathname} = useLocation()
  useEffect(() => {
    if (hash && hash === '#testimonial' && pathname === '/') {
      scroller.scrollTo('testimonials', {
        duration: 1000,
        delay: 100,
        smooth: true,
      })
      window.history.replaceState('', 'New Page Title', '/')
    }
  }, [hash, key])
  return (
    <Element name="testimonials" id="testimonial">
      <span className={classes.title} >Відгуки</span>
      {!isMobile ? (
        <div className={classes.wrapper}>
          {testimonials.map(({ name, testimonial, key, email, logo, link }) => (
            <div key={key} className={classes.authorBlock}>
              <div className={classes.wrapperItem}>
                <img src={logo} alt="logo" className={classes.logo} />
                <div>
                  <div className={classes.name}>{name}</div>
                  <div className={classes.testimonial}>{testimonial}</div>
                  <a target="_blank" href={link} className={classes.nickName}>{email}</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Slider array={testimonials} />
      )}
    </Element>
  );
};

export default Testimonials;
