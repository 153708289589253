import LOGO from 'assets/images/logo.svg';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'services/hooks/useDimensions/useWindowDimension';

import { EDimensions } from '../../constans';
import { useStyles } from './styles';

const Footer: React.FC = () => {
  const classes = useStyles();
  const dimensions = useWindowDimensions();
  const isMob = dimensions === EDimensions.SMALL;
  const navigate = useNavigate();
  return (
    <div className={classes.wrapper}>
      <img
        src={LOGO}
        className={classes.logo}
        alt="logo"
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
      />
      <div className={classes.navigation}>
        <div className={classes.column}>
          <a href="/" className={classes.name}>Головна</a>
          <Link to="/#service" className={classes.socialItem}>
            Види послуг
          </Link>
          <Link to="/#testimonial" className={classes.socialItem}>
            Відгуки
          </Link>
          <Link to="/#franchise" className={classes.socialItem}>
            Франшиза
          </Link>
        </div>
        {
          !isMob ? (
            <div className={classes.column}>
              <a href="/about-us" className={`${classes.name}`}>Про нас</a>
              <Link to="about-us/#who" className={classes.socialItem}>
                Хто ми?
              </Link>
              <Link to="about-us/#video" className={classes.socialItem}>
                Рекомендації
              </Link>
              <Link to="about-us/#center" className={classes.socialItem}>
                Наші центри
              </Link>
              <Link to="about-us/#social" className={classes.socialItem}>
                Ми в соцмережах
              </Link>
            </div>

          ) : (
            <div className={classes.column}>
              <a href="/season-tickets" className={classes.name}>Абонементи</a>
              <div className={classes.column}>
                <a href="/result" className={`${classes.name} ${classes.marginTop}`}>Результати</a>
              </div>
            </div>
          )
        }
        {
          !isMob ? (
            <div className={classes.column}>
                <a href="/season-tickets" className={classes.name}>Абонементи</a>
                <div className={classes.column}>
                  <a href="/result" className={`${classes.name} ${classes.marginTop}`}>Результати</a>
                </div>
              </div>
          ) : (
            <div className={classes.column}>
              <a href="/about-us" className={`${classes.name} ${classes.marginTop}`}>Про нас</a>
              <Link to="about-us/#who" className={classes.socialItem}>
                Хто ми?
              </Link>
              <Link to="about-us/#video" className={classes.socialItem}>
                Рекомендації
              </Link>
              <Link to="about-us/#center" className={classes.socialItem}>
                Наші центри
              </Link>
              <Link to="about-us/#social" className={classes.socialItem}>
                Ми в соцмережах
              </Link>
            </div>
          )
        }
        <div className={classes.column}>
          <a className={classes.name}>Контакти</a>
          <div className={classes.contactItem}>
            <div><span>м. Київ</span> вул. Нивська 4Г</div>
            <a href="tel:+380509500101">+380509500101</a>
          </div>
          <div className={classes.contactItem}>
            <div><span>м. Київ</span> вул. Срібнокільська 1</div>
            <a href="tel:+380977011770">+380977011770</a>
          </div>
          <div className={classes.contactItem}>
            <div><span>м. Харків</span> вул. Клочківська 99А</div>
            <a href="tel:+380983131300">+380983131300</a>
          </div>
          <div className={classes.contactItem}>
            <div><span>м. Одеса</span> вул. Каманіна 16А</div>
            <a href="tel:+380977490707">+380977490707</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
