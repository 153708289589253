import { IDataModel, IValidateModel, TICKETS } from '../interfaces';
export const validate: IValidateModel = {
  checked_1: false,
  checked_2: false,
  checked_3: false,
  checked_4: false,
  checked_5: false,
  checked_6: false,
  checked_7: false,
  checked_8: false,
  checked_9: false,
  checked_10: false,
  checked_11: false,
  checked_12: false,
  checked_13: false,
  checked_14: false,
  checked_15: false,
  checked_16: false,
  checked_17: false,
  checked_18: false,
  checked_19: false,
  checked_20: false,
  checked_21: false,
  checked_22: false,
  checked_23: false,
  checked_24: false,
  checked_25: false,
  checked_26: false,
  checked_27: false,
  checked_28: false,
  checked_29: false,
  checked_30: false,
};

export const questions: IDataModel[] = [
  {
    key: 1,
    title: 'запитання 1',
    question: 'Які зони вам би хотілось скорегувати?',
    answers: [
      {
        key: 1,
        name: 'Живіт та боки',
        second_row: false,
        checkbox: false,
      },
      {
        key: 2,
        name: 'Сідниці та ноги',
        second_row: false,
        checkbox: false,
      },
      {
        key: 3,
        name: 'Зони галіфе',
        second_row: false,
        checkbox: false,
      },
      {
        key: 4,
        name: 'Руки, спина',
        second_row: false,
        checkbox: false,
      },
      {
        key: 5,
        name: 'Зайвих об`ємів нема, але турбує целлюлит',
        second_row: true,
        checkbox: false,
      },
      {
        key: 6,
        name: 'Друге підборіддя',
        second_row: false,
        checkbox: false,
      },
    ],
  },
  {
    key: 2,
    title: 'запитання 2',
    question:
      'Чи схильні ви до появи набряків? Можливо, помічали тяжкість у ногах ввечері, ' +
      'сліди резинок від шкарпеток, або смужки від джинсів на тілі?',
    answers: [
      {
        key: 7,
        name: 'Так',
        second_row: false,
        checkbox: false,
      },
      {
        key: 8,
        name: 'Ні',
        second_row: false,
        checkbox: false,
      },
    ],
  },
  {
    key: 3,
    title: 'запитання 3',
    question: 'Чи маєте ви алергію на мед?',
    answers: [
      {
        key: 9,
        name: 'Так',
        second_row: false,
        checkbox: false,
      },
      {
        key: 10,
        name: 'Ні',
        second_row: false,
        checkbox: false,
      },
    ],
  },
  {
    key: 4,
    title: 'запитання 4',
    question: 'Наскільки ваша вага відрізняеться зараз від вашої ідеальної?',
    answers: [
      {
        key: 11,
        name: 'Маю скинути 2-3 кг і буде ідеальна',
        second_row: true,
        checkbox: false,
      },
      {
        key: 12,
        name: 'Мене влаштовує вага, я лише хочу покращити стан шкіри',
        second_row: true,
        checkbox: false,
      },
      {
        key: 13,
        name: 'У мене зайвих біля 10 кг, але я налаштована їх позбутися',
        second_row: true,
        checkbox: false,
      },
      {
        key: 14,
        name: 'Я вже значно схудла за останній час і відчуваю, що мені необхідний масаж для тонуса шкіри',
        second_row: true,
        checkbox: false,
      },
    ],
  },
  {
    key: 5,
    title: 'запитання 5',
    question: 'Чи маєте ви щось з цього переліку?',
    answers: [
      {
        key: 15,
        name: 'Другий триместр вагітності',
        second_row: false,
        checkbox: false,
      },
      {
        key: 16,
        name: 'Перший триместр вагітності, онкозахворювання, гемофілія, цукровий діабет 2 типу',
        second_row: true,
        three_row: true,
        checkbox: false,
      },
      {
        key: 17,
        name: 'ГВ',
        second_row: true,
        checkbox: false,
      },
      {
        key: 18,
        name: 'Гіпертонія, операційні втручання протягом останніх 3 місяців, прийом кроворозріджуючих препаратів',
        second_row: true,
        three_row: true,
        checkbox: false,
      },
      {
        key: 19,
        name: 'Варикозне розширення вен на ділянках, які ви хочете скорегувати',
        second_row: true,
        three_row: true,
        checkbox: false,
      },
      {
        key: 20,
        name: 'Судинні сітки, зірочки',
        second_row: false,
        checkbox: false,
      },
      {
        key: 21,
        name: 'Не маю нічого з переліченого',
        second_row: true,
        checkbox: false,
      },
    ],
  },
  {
    key: 6,
    title: 'запитання 6',
    question: 'Скільки вам років?',
    answers: [
      {
        key: 22,
        name: '18 – 25 років',
        second_row: false,
        checkbox: false,
      },
      {
        key: 23,
        name: '26 – 35 років',
        second_row: false,
        checkbox: false,
      },
      {
        key: 24,
        name: '36 – 50 років',
        second_row: false,
        checkbox: false,
      },
      {
        key: 25,
        name: '51 – 65 років',
        second_row: false,
        checkbox: false,
      },
    ],
  },
  {
    key: 7,
    title: 'запитання 7',
    question: 'З якого ви міста?',
    answers: [
      {
        key: 26,
        name: 'Харків',
        second_row: false,
        checkbox: false,
      },
      {
        key: 27,
        name: 'Київ правий берег',
        second_row: false,
        checkbox: false,
      },
      {
        key: 28,
        name: 'Київ лівий берег',
        second_row: false,
        checkbox: false,
      },
      {
        key: 29,
        name: 'Одеса',
        second_row: false,
        checkbox: false,
      },
      {
        key: 30,
        name: 'Інше місто',
        second_row: false,
        checkbox: false,
      },
    ],
  },
];

export const NAVIGATION_ITEMS = [
  {
    key: 1,
    name: 'Головна',
    path: '/',
  },
  {
    key: 2,
    name: 'Про нас',
    path: 'about-us',
  },
  {
    key: 3,
    name: 'Абонементи',
    path: 'season-tickets',
  },
  {
    key: 4,
    name: 'Результати',
    path: 'result',
  },
];

export const TICKETS_SENTENCES = [
  {
    key: 1,
    name: 'XS max',
    type: TICKETS.XS_MAX,
    sentence: 'Цілих 20 процедур, серед яких: 10 процедур ручної ліпосакції, ' +
      '5 обгортань, що зменшують об’єми тіла та виводять рідину (STYX або Bruno Vassari) та ' +
      '5 ін’єкцій ліполітика для корекції проблемних зон + 1 процедура в подарунок.'
  },
  {
    key: 2,
    name: 'Light fit',
    type: TICKETS.LIGHT_FIT,
    sentence: 'Найефективніша процедура для худого обличчя, ' +
      'така ж безпощадна до другого підборіддя та брилів, як наші ЗСУ до самі знаєте кого. ' +
      'Проводиться з використанням непрямого ліполітика. Абонемента немає, бо ефект видно одразу.'
  },
  {
    key: 3,
    name: 'S',
    type: TICKETS.S,
    sentence: 'Найулюбленіший абонемент наших дівчат. ' +
      'В нього входить 10 процедур ручної ліпосакції та 5 обгортань, ' +
      'що зменшують об’єми тіла та виводять зайву рідину (STYX або Bruno Vassari) + 1 процедура в подарунок.'
  },
  {
    key: 4,
    name: 'FBB',
    type: TICKETS.FBB,
    sentence: 'Комплекс, що містить 10 процедур загального лімфодренажного масажу з використанням блейдів. ' +
      'Ідеально підійде для того, щоб прискорити обмін речовин у всьому організмі ' +
      'та придати відчуття легкості й тонусу.'
  },
  {
    key: 5,
    name: 'DBB',
    type: TICKETS.DBB,
    sentence: 'Найефективніший абонемент для боротьби з фіброзним целюлітом (ямки та горбики). ' +
      'Містить 10 процедур, кожна з яких поєднує ручну ліпосакцію та блейди. ' +
      'Працюємо зонами. Процедура має підвищену ефективність.'
  },
  {
    key: 6,
    name: 'Тейпи',
    type: TICKETS.TAPES,
    sentence: 'Майже необхідне доповнення до будь-якого абонемента, якщо ваша пріоритетна зона — живіт і боки. ' +
      'Ми робимо тейпування для корекції фігури, відновлення після пологів, а також лімфодренажне.'
  },
  {
    key: 7,
    name: 'XS',
    type: TICKETS.XS,
    sentence: 'Містить 10 процедур ручної ліпосакції + 5 ін’єкцій ліполітиків + 1 процедура в подарунок. ' +
      'Підходить для тих зон, які не підлягають корекції за допомогою харчування, спорту та масажу.'
  },
  {
    key: 8,
    name: 'S холодні обгортання',
    type: TICKETS.COLD_WRAPS,
    sentence: 'Абонемент ідеально підійде для матусь, які на ГВ, ' +
      'а також для тих, хто має схильність до захворювань серцево-судинної системи. ' +
      'Містить 10 процедур ручної ліпосакції + 5 холодних обгортань + 1 процедура в подарунок'
  },
  {
    key: 9,
    name: 'Ліфтинг обличчя',
    type: TICKETS.FACE_LIFTING,
    sentence: 'Ми розробили абонементи на 5, 10 або 20 процедур авторського масажу обличчя. ' +
      'Абонемент з  п’яти процедур — це найпопулярніший подарунковий сертифікат у нашому центрі, ' +
      'а з двадцяти — це вже освідчення собі в коханні.'
  },
  {
    key: 10,
    name: 'Базовий',
    type: TICKETS.BASE,
    sentence: 'Містить 10 процедур ручної ліпосакції + 1 в подарунок. ' +
      'Одна процедура складається з медового, ручного, лімфодренажного, ' +
      'вакуумного масажу та завершального обгортання з термокремом.'
  },
  {
    key: 11,
    name: 'Базовий + тейпи',
    type: TICKETS.BASE_PLUS_TYPE,
    sentence: 'Містить 10 процедур ручної ліпосакції + 1 в подарунок. ' +
      'Одна процедура складається з медового, ручного, лімфодренажного, ' +
      'вакуумного масажу та завершального обгортання з термокремом.' +
      'Ми робимо тейпування для корекції фігури, відновлення після пологів, а також лімфодренажне.'
  },
  {
    key: 12,
    name: 'Тейпи + «S»',
    type: TICKETS.S_PLUS_TYPE,
    sentence: 'Майже необхідне доповнення до будь-якого абонемента, якщо ваша пріоритетна зона — живіт і боки. ' +
      'Ми робимо тейпування для корекції фігури, відновлення після пологів, а також лімфодренажне. ' +
      'Процедури ручної ліпосакції та 5 обгортань зменшують об’єми тіла та виводять зайву рідину.'
  },
]

export enum EDimensions {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  MIDDLE = 'MIDDLE',
  DESKTOP = 'DESKTOP',
  UNKNOWN = 'UNKNOWN',
}

export const DESKTOP_WIDTH = 1001
export const LAPTOP_WIDTH = 1250;
export const MIDDLE_WIDTH = 1000;
export const TABLET_WIDTH = 768;

export const MAX_WIDTH_1400 = '@media (max-width: 1400px)';
export const MAX_WIDTH_1200 = '@media (max-width: 1250px)';
export const MAX_WIDTH_1000 = '@media (max-width: 1000px)';
export const MAX_WIDTH_767 = '@media (max-width: 767px)';
export const MAX_WIDTH_320 = '@media (max-width: 320px)';
